import * as React from "react";
import { ThemeProvider } from "styled-components";
import siteTheme, { GlobalStyles } from "../utils/siteStyles";
import { HeadContents } from "../utils/siteTools";
import { SiteContextProvider } from "../utils/siteContext";
import PageLoadingContainer from '../sections/loading/PageLoadingContainer';
import { auth, useAuth, firestore, useFirestoreDoc } from "gatsby-theme-firebase";

const Layout = ({ children }) => {

  if (auth && auth.currentUser && auth.currentUser.uid ) {

      let curUser = auth.currentUser;

      const [data, isLoading, error] = useFirestoreDoc(
        firestore.collection("users").doc(curUser && curUser.uid),
      );

       if (isLoading)
        return null
  }

  return (
    <ThemeProvider theme={siteTheme}>
      <SiteContextProvider>
        <>
          <GlobalStyles />
          {children}
        </>
      </SiteContextProvider>
  </ThemeProvider>
  );
}

export default Layout;
